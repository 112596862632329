import { SfLoader, SfHeading } from '@storefront-ui/vue';
import { defineComponent, ref, useFetch, useContext, } from '@nuxtjs/composition-api';
import { useCache, CacheTagPrefix } from '@vue-storefront/cache';
import { getMetaInfo } from '~/helpers/getMetaInfo';
import { useContent } from '~/composables';
import { usePageStore } from '~/stores/page';
import HTMLContent from '~/components/HTMLContent.vue';
export default defineComponent({
    name: 'CmsPage',
    components: {
        HTMLContent,
        SfLoader,
        SfHeading,
    },
    setup() {
        const { routeData } = usePageStore();
        const { addTags } = useCache();
        const { error: nuxtError } = useContext();
        const { loadPage, loading, error, } = useContent();
        const page = ref(null);
        useFetch(async () => {
            var _a;
            page.value = await loadPage({ identifier: routeData.identifier });
            if (((_a = error === null || error === void 0 ? void 0 : error.value) === null || _a === void 0 ? void 0 : _a.page) || !page.value)
                nuxtError({ statusCode: 404 });
            addTags([{ prefix: CacheTagPrefix.View, value: routeData.identifier }]);
        });
        return {
            page,
            loading,
        };
    },
    head() {
        return getMetaInfo(this.page);
    },
});
